<template>
  <div class="promo-cards">
    <router-link
      class="router-link"
      :to="item.to"
      v-for="item in items"
      :key="item.id"
    >
      <img class="bg" :src="item.src" />
    </router-link>
  </div>
</template>

<script>
import { nanoid } from "nanoid";

export default {
  name: "PromoCards",
  data() {
    return {
      items: [
        {
          id: nanoid(),
          src: require("@/assets/images/h5/home/promo-card1.png"),
          to: "/mCommission",
        },
        {
          id: nanoid(),
          src: require("@/assets/images/h5/home/promo-card2.png"),
          to: "/mNewSignup",
        },
        {
          id: nanoid(),
          src: require("@/assets/images/h5/home/promo-card3.png"),
          to: "/mBetRebate",
        },
        {
          id: nanoid(),
          src: require("@/assets/images/h5/home/promo-card4-1.png"),
          to: "/mRecommendation",
        },
        {
          id: nanoid(),
          src: require("@/assets/images/h5/home/promo-card5.png"),
          to: "/mOverallRanking",
        },
        {
          id: nanoid(),
          src: require("@/assets/images/h5/home/promo-card6.png"),
          to: "/mWeeklyRanking",
        },
        // mDoubleGift内页切换tab
        // {
        //   id: nanoid(),
        //   src: require("@/assets/images/h5/home/promo-card5.png"),
        //   to: "/mDoubleGift",
        // },
        // {
        //   id: nanoid(),
        //   src: require("@/assets/images/h5/home/promo-card7.png"),
        //   to: "/mOverallRanking",
        // },
        // {
        //   id: nanoid(),
        //   src: require("@/assets/images/h5/home/promo-card8.png"),
        //   to: "/mGoodGift",
        // },
      ],
    };
  },
};
</script>

<style lang="less">
.promo-cards {
  // margin-top: 4px;
  padding-bottom: 64px;

  .router-link {
    position: relative;

    .bg {
      display: block;
      width: 340px;
      height: 130px;
      margin: 5px auto;
      margin-bottom: 10px;
    }
  }
}
</style>
