<template>
  <div class="home-header bottom-shadowed" :style="{ height }">
    <img src="@/assets/images/h5/home/home-header-1.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "HomeHeader",
  props: {
    height: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="less" scoped>
.home-header {
  background-color: #fff;
  position: fixed;
  width: 100%;
  z-index: 8;
  height: 40px;

  img {
    width: 100%;
    // height: 100%;
  }
}
</style>
